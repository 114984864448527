.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.swiper-button-prev {
    position: absolute;
    top: 50%;
    left: 75px;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    &::after {
        display: none;
    }
    &.disable {
        /* background: rgba(255, 255, 255, 0.5); */
    }
}

.swiper-button-next {
    position: absolute;
    top: 50%;
    right: 75px;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    &::after {
        display: none;
    }
    &.disable {
        /* background: rgba(255, 255, 255, 0.5); */
    }
}
