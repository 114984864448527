.custom-bullet {
    height: 4px;
    border-radius: 0;
    &.swiper-pagination-bullet {
        width: 50px;
        background-color: #9a9a9a;
    }
    &.swiper-pagination-bullet-active {
        width: 300px;
        background-color: #fff;
    }
}
